import { useState } from 'react'
import ReactGA from 'react-ga4'
import 'react-loading-skeleton/dist/skeleton.css'
import { SpinnerCircular } from 'spinners-react'
import Swal from 'sweetalert2'

const googleAnalitcsID = process.env.REACT_APP_GOOGLE_ANALITCS_ID
ReactGA.initialize(googleAnalitcsID)

const api_csc = process.env.REACT_APP_API_PORTAL_CSC

const BtnSolicitarBoleto = (props) => {
  const [issend, setIssend] = useState(false)
  const [statussend, setStatussend] = useState('Solicitar boleto')

  const primaryColor = localStorage.getItem('@meuboleto/bgcolor')
    ? localStorage.getItem('@meuboleto/bgcolor')
    : '#1A508C'

  const mostrarMsg = () => {
    Swal.fire({
      title: 'Solicitação de registro',
      text: 'Prezado, o seu boleto foi registrado e já está disponível para pagamento no portal.',
      icon: 'success',
      confirmButtonText: 'Ok',
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.reload()
      }
    })
  }

  return (
    <button
      variant="success"
      class={`btn-block btn mr-1`}
      style={{
        cursor: 'pointer',
        fontSize: '0.7rem',
        backgroundColor: primaryColor,
        color: '#fff',
        padding: '6px',
      }}
      onClick={() => {
        setIssend(true)

        var myHeaders = new Headers()
        myHeaders.append('Content-Type', 'application/json')

        var raw = JSON.stringify([
          {
            CODCOLIGADA: props.codcoligada,
            IDBOLETO: props.idboleto,
            IDLAN: props.idlan,
          },
        ])

        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow',
        }

        fetch(
          `${api_csc}/api/totvs/boleto/remeter?email=admin.ti@redeinspiraeducadores.com.br`,
          requestOptions
        )
          .then((response) => {
            if (response.status === 204) {
              Swal.fire({
                title: 'Boleto enviado para registro',
                text: 'Seu boleto estará disponível em até 2 dias úteis.',
                icon: 'info',
                confirmButtonText: 'Ok',
              })
            }
            if (response.status === 200) {
              setStatussend('Registrado')
              mostrarMsg()
            }
            if (response.status === 500) {
              response.text().then((val) => {
                throw Error(val)
              })
            }
            setIssend(false)
            return response
          })
          .catch((error) => {
            console.log('error', error)
            Swal.fire({
              title: 'Status',
              text: error,
              icon: 'warning',
              confirmButtonText: 'Ok',
            })
            setIssend(false)
            setStatussend('Solicitar boleto')
          })
      }}
    >
      <i class="fas fa-file-import" style={{ marginRight: '5px' }}></i>
      {issend ? (
        <SpinnerCircular
          color="rgba(255, 255, 255, 1)"
          secondaryColor="rgba(0, 0, 0, 0.44)"
          size={20}
        />
      ) : props.statucnab === 1 ? (
        'Solicitar boleto'
      ) : (
        statussend
      )}
    </button>
  )
}

export default BtnSolicitarBoleto
