import React, { useState, useEffect } from 'react'
import BtnBoleto from './BtnBoleto'
import BtnCartao from './BtnCartao'
import BtnPix from './BtnPix'
import BtnNfse from './BtnNfse'
import SolicitarBoletos from './BtnSolicitarBoleto'
import { Button } from 'reactstrap'
import { lighten } from 'polished'

const api = process.env.REACT_APP_HOST
const api_matriculei = process.env.REACT_APP_API_MATRICULEI

const TableButtonsMD = ({ itemMaterial, user }) => {
  const [dadosparamsmd, setDadosParamsMd] = useState([])
  const [contratouServico, setContratouServico] = useState(false)
  const primaryColor = localStorage.getItem('@meuboleto/bgcolor') || '#1A508C'
  const lighterPrimaryColor = lighten(0.2, primaryColor)

  const temAnuidade = (servico) => {
    // if (servico.length > 0) {
    //   const servicoNomeMin = servico.toLowerCase()
    //   return servicoNomeMin.includes('anuidade')
    // }
    return false
  }

  const servicoContratadoCartao = async (codcoligada, idboleto) => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow',
    }

    let meioPgto = await fetch(
      `${api_matriculei}/api/Matriculas/ObterDadosParcelaMd/${codcoligada}/${idboleto}/${itemMaterial.RA}`,
      requestOptions
    )
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText)
        }
        return response.json()
      })
      .then((result) => {
        if (result.meioPgto === 'CC') {
          return true
        } else {
          return false
        }
      })
      .catch((error) => {
        console.log('error', error)
        return false
      })
    return meioPgto
  }

  useEffect(() => {
    servicoContratadoCartao(
      itemMaterial.CODCOLIGADA,
      itemMaterial.IDBOLETO ? itemMaterial.IDBOLETO : 0
    )
      .then((formaPagto) => {
        if (formaPagto === true) {
          setContratouServico(true)
        } else {
          setContratouServico(false)
        }
      })
      .catch((error) => {
        setContratouServico(false)
      })
  }, [itemMaterial])

  return (
    <>
      <div className="d-flex align-items-center">
        {itemMaterial && (
          <>
            {dadosparamsmd !== undefined &&
            dadosparamsmd.PAGAGENDADOMD === 'S' ? (
              itemMaterial.AGENDAMENTOSTATUS === 'AGENDADO' ? (
                <>{/* botão para cancelar agendamento */}</>
              ) : itemMaterial.AGENDAMENTOSTATUS === 'PREAGENDADO' ? (
                <>{/* botão para pré-agendamento */}</>
              ) : itemMaterial.BTN_STATUS === 'Imprimir' ? (
                <>
                  <BtnBoleto
                    ra={itemMaterial.RA}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    codfilial={itemMaterial.CODFILIAL}
                    codperlet={itemMaterial.CODPERLET}
                    codturma={itemMaterial.CODTURMA}
                    idboleto={itemMaterial.IDBOLETO}
                    ipte={itemMaterial.IPTE}
                    codbarras={itemMaterial.CODIGOBARRAS}
                    tipo="md"
                  />
                  <BtnCartao
                    ra={itemMaterial.RA}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    codfilial={itemMaterial.CODFILIAL}
                    codperlet={itemMaterial.CODPERLET}
                    codturma={itemMaterial.CODTURMA}
                    idboleto={itemMaterial.IDBOLETO}
                    idlan={itemMaterial.IDLAN}
                    tipoparcelamento="MD"
                    statusAg={itemMaterial.AGENDAMENTOSTATUS}
                  />
                  <BtnPix
                    ra={itemMaterial.RA}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    codfilial={itemMaterial.CODFILIAL}
                    codperlet={itemMaterial.CODPERLET}
                    codturma={itemMaterial.CODTURMA}
                    idboleto={itemMaterial.IDBOLETO}
                    tipoparcelamento="MD"
                  />
                </>
              ) : itemMaterial.BTN_STATUS === 'Pago' ? (
                <>
                  <Button
                    variant="success"
                    className={`btn-block btn mr-1`}
                    style={{
                      cursor: 'pointer',
                      fontSize: '0.7rem',
                      backgroundColor: primaryColor,
                      color: '#fff',
                    }}
                  >
                    <i
                      className="fas fa-dollar-sign"
                      style={{ marginRight: '5px' }}
                    ></i>{' '}
                    Pago
                  </Button>
                </>
              ) : itemMaterial.BTN_STATUS === 'Solicitar' ? (
                <>
                  <SolicitarBoletos
                    statucnab={itemMaterial.statucnab}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    idboleto={itemMaterial.IDBOLETO}
                    idlan={itemMaterial.IDLAN}
                  />
                  <BtnCartao
                    ra={itemMaterial.RA}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    codfilial={itemMaterial.CODFILIAL}
                    codperlet={itemMaterial.CODPERLET}
                    codturma={itemMaterial.CODTURMA}
                    idboleto={itemMaterial.IDBOLETO}
                    idlan={itemMaterial.IDLAN}
                    tipoparcelamento="MD"
                    statusAg={itemMaterial.AGENDAMENTOSTATUS}
                  />
                  <BtnPix
                    ra={itemMaterial.RA}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    codfilial={itemMaterial.CODFILIAL}
                    codperlet={itemMaterial.CODPERLET}
                    codturma={itemMaterial.CODTURMA}
                    idboleto={itemMaterial.IDBOLETO}
                    tipoparcelamento="MD"
                  />
                </>
              ) : null
            ) : itemMaterial.BTN_STATUS === 'Imprimir' ? (
              <>
                {
                  <BtnBoleto
                    ra={itemMaterial.RA}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    codfilial={itemMaterial.CODFILIAL}
                    codperlet={itemMaterial.CODPERLET}
                    codturma={itemMaterial.CODTURMA}
                    idboleto={itemMaterial.IDBOLETO}
                    statusboleto={itemMaterial.CNABSTATUS_BOLETO}
                    ipte={itemMaterial.IPTE}
                    codbarras={itemMaterial.CODIGOBARRAS}
                    tipo="md"
                  />
                }

                <BtnCartao
                  ra={itemMaterial.RA}
                  codcoligada={itemMaterial.CODCOLIGADA}
                  codfilial={itemMaterial.CODFILIAL}
                  codperlet={itemMaterial.CODPERLET}
                  codturma={itemMaterial.CODTURMA}
                  idboleto={itemMaterial.IDBOLETO}
                  idlan={itemMaterial.IDLAN}
                  tipoparcelamento="MD"
                  statusAg={itemMaterial.AGENDAMENTOSTATUS}
                />
                <BtnPix
                  ra={itemMaterial.RA}
                  codcoligada={itemMaterial.CODCOLIGADA}
                  codfilial={itemMaterial.CODFILIAL}
                  codperlet={itemMaterial.CODPERLET}
                  codturma={itemMaterial.CODTURMA}
                  idboleto={itemMaterial.IDBOLETO}
                  tipoparcelamento="MD"
                />
              </>
            ) : itemMaterial.BTN_STATUS === 'NExibir' ? (
              <></>
            ) : itemMaterial.BTN_STATUS === 'Pago' ? (
              <>
                <Button
                  variant="success"
                  className={`btn-block btn mr-1`}
                  style={{
                    cursor: 'pointer',
                    fontSize: '0.7rem',
                    backgroundColor: primaryColor,
                    color: '#fff',
                    padding: '6px',
                  }}
                >
                  <i
                    className="fas fa-dollar-sign"
                    style={{ marginRight: '5px' }}
                  ></i>{' '}
                  Pago
                </Button>
              </>
            ) : itemMaterial.BTN_STATUS === 'Solicitar' ? (
              <>
                <SolicitarBoletos
                  statucnab={itemMaterial.CNABSTATUS_BOLETO}
                  codcoligada={itemMaterial.CODCOLIGADA}
                  idboleto={itemMaterial.IDBOLETO}
                  idlan={itemMaterial.IDLAN}
                />
                <BtnCartao
                  ra={itemMaterial.RA}
                  codcoligada={itemMaterial.CODCOLIGADA}
                  codfilial={itemMaterial.CODFILIAL}
                  codperlet={itemMaterial.CODPERLET}
                  codturma={itemMaterial.CODTURMA}
                  idboleto={itemMaterial.IDBOLETO}
                  idlan={itemMaterial.IDLAN}
                  tipoparcelamento="MD"
                  statusAg={itemMaterial.AGENDAMENTOSTATUS}
                />
                <BtnPix
                  ra={itemMaterial.RA}
                  codcoligada={itemMaterial.CODCOLIGADA}
                  codfilial={itemMaterial.CODFILIAL}
                  codperlet={itemMaterial.CODPERLET}
                  codturma={itemMaterial.CODTURMA}
                  idboleto={itemMaterial.IDBOLETO}
                  tipoparcelamento="MD"
                />
              </>
            ) : itemMaterial.BTN_STATUS === 'BoletonaoRegistrado' ? (
              <>
                {itemMaterial.NUMBANCO === '001' ? (
                  <SolicitarBoletos
                    codcoligada={itemMaterial.CODCOLIGADA}
                    statucnab={itemMaterial.CNABSTATUS_BOLETO}
                    idboleto={itemMaterial.IDBOLETO}
                    idlan={itemMaterial.IDLAN}
                  />
                ) : (
                  <BtnBoleto
                    idboleto={itemMaterial.IDBOLETO}
                    ra={itemMaterial.RA}
                    codcoligada={itemMaterial.CODCOLIGADA}
                    codfilial={itemMaterial.CODFILIAL}
                    codperlet={itemMaterial.CODPERLET}
                    codturma={itemMaterial.CODTURMA}
                    ipte={itemMaterial.IPTE}
                    codbarras={itemMaterial.CODIGOBARRAS}
                    tipo="material"
                    acao="solicitar"
                  />
                )}
                <BtnCartao
                  ra={itemMaterial.RA}
                  codcoligada={itemMaterial.CODCOLIGADA}
                  codfilial={itemMaterial.CODFILIAL}
                  codperlet={itemMaterial.CODPERLET}
                  codturma={itemMaterial.CODTURMA}
                  idboleto={itemMaterial.IDBOLETO}
                  idlan={itemMaterial.IDLAN}
                  tipoparcelamento="MD"
                  statusAg={itemMaterial.AGENDAMENTOSTATUS}
                />
              </>
            ) : null}
          </>
        )}
      </div>
    </>
  )
}
export default TableButtonsMD
